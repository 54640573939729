@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumBarunGothic@latest/nanumbarungothicsubset.css');

@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css');
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.godo * {
  font-family: 'Godo', sans-serif;
}
body {
  width: fit-content;
  position: relative;
  min-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'NanumBarunGothic', 'NanumSquare', Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

textarea {
  font-family: 'NanumBarunGothic', 'NanumSquare', Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  padding-bottom: 8rem;
  background: #f1f3f5;
}

html {
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
